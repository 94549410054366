<template>
  <svg
    id="Capa_1"
    fill="#000000"
    height="24px"
    width="24px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 248.57 248.57"
    xml:space="preserve"
  >
    <g>
      <path
        d="M241.07,42.767h-26.709V17.745c0-4.142-3.358-7.5-7.5-7.5H7.5c-4.142,0-7.5,3.358-7.5,7.5V213.72
		c0,13.567,11.038,24.605,24.605,24.605h199.359c13.567,0,24.605-11.038,24.605-24.605V50.267
		C248.57,46.125,245.212,42.767,241.07,42.767z M15,25.245h184.361v25.021V213.72c0,3.406,0.696,6.653,1.953,9.605H24.605
		c-5.296,0-9.605-4.309-9.605-9.605V25.245z M223.965,223.325c-5.295,0-9.604-4.309-9.604-9.605V57.767h19.209V213.72
		C233.57,219.016,229.261,223.325,223.965,223.325z"
      />
      <path
        d="M32.422,107.085h149.52c4.142,0,7.5-3.358,7.5-7.5V49.461c0-4.142-3.358-7.5-7.5-7.5H32.422c-4.142,0-7.5,3.358-7.5,7.5
		v50.124C24.922,103.727,28.28,107.085,32.422,107.085z M39.922,56.961h134.52v35.124H39.922V56.961z"
      />
      <path
        d="M32.422,205.672H97.73c4.142,0,7.5-3.358,7.5-7.5v-65.313c0-4.142-3.358-7.5-7.5-7.5H32.422c-4.142,0-7.5,3.358-7.5,7.5
		v65.313C24.922,202.314,28.28,205.672,32.422,205.672z M39.922,140.358H90.23v50.313H39.922V140.358z"
      />
      <path
        d="M181.941,125.358h-63.16c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h63.16c4.142,0,7.5-3.358,7.5-7.5
		C189.441,128.716,186.083,125.358,181.941,125.358z"
      />
      <path
        d="M181.941,190.672h-63.16c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h63.16c4.142,0,7.5-3.358,7.5-7.5
		C189.441,194.03,186.083,190.672,181.941,190.672z"
      />
      <path
        d="M181.941,158.011h-63.16c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h63.16c4.142,0,7.5-3.358,7.5-7.5
		C189.441,161.369,186.083,158.011,181.941,158.011z"
      />
    </g>
  </svg>
</template>
