<script setup lang="ts">
import "./v2.css"
import { useIntl } from "~/composables/intl"
import { navigateTo, useAuth, useRoute } from "#imports"
import { onMounted, ref, watch, computed } from "vue"
import { run, useSafeSuspenseQuery } from "~/composables/client"
import { NonEmptyString255 } from "effect-app/Schema"
import { Effect } from "effect"
import { useClarity } from "~/composables/clarity"
import HeaderV3 from "~/components/HeaderV3.vue"
import type { TickerSearchItemV3 } from "#resources/TickersV3"
import {
  mdiLogout,
  mdiHelp,
  mdiCog,
  mdiWallet,
  mdiShopping,
  mdiMagnify,
  mdiApplication,
} from "@mdi/js"
import { isMobile } from "~/composables/useDevice"
import { useAuthUser } from "~/composables/currentUser"
import { tickersV3Client } from "~/composables/clients"

const mobileView = isMobile()

const route = useRoute()

const sess = useAuth()

const { locale } = useIntl()

const { trans } = useIntl()
// Monitor the access token, if it's about to expire, do a token refresh?
onMounted(() => {
  // TODO: Instead of doing this every hour, do it on api client authentication error
  // and retry the request.. ask the user if we can reload to login?
  setInterval(() => sess.getSession(), 1 * 60 * 60 * 1000)
})

const clarity = useClarity()

const isAdmin = computed(() => route.path.startsWith("/admin"))

const isMobileView = isMobile()

const useMobileBottomNavigation = computed(
  () => isMobileView.value && !isAdmin.value,
)

const query = ref<string>("")
const queryToSearch = ref<string>("")

const wrappedSearch = {
  ...tickersV3Client.SearchV3,
  handler: (query: string) =>
    query
      ? tickersV3Client.SearchV3.handler({
          query: NonEmptyString255(query),
        }).pipe(
          Effect.tap(
            queryToSearch.value
              ? clarity.emitCustomEventLogError("SecuritySearch")
              : Effect.void,
          ),
          Effect.map(_ => _.items),
        )
      : tickersV3Client.HighlightsV3.handler.pipe(Effect.map(_ => _.items)),
}

const [_, securities] = await run(
  useSafeSuspenseQuery(wrappedSearch, queryToSearch, {
    refetchOnWindowFocus: false,
  }),
)

let debounceTimeout: number | undefined

watch(query, () => {
  clearTimeout(debounceTimeout)
  debounceTimeout = window.setTimeout(() => {
    queryToSearch.value = query.value
  }, 500)
})

const isDrawerOpen = ref(false)

const onSecurityClick = (security: TickerSearchItemV3) => {
  navigateTo(`/securities/${security.symbol}`)
}

const user = useAuthUser()

const role = computed(() => user.role)
</script>

<template>
  <v-navigation-drawer
    v-if="mobileView"
    v-model="isDrawerOpen"
    location="right"
    style="background-color: rgb(18, 18, 18)"
  >
    <v-list>
      <v-list-item>
        <NuxtLink
          :to="{ name: 'settings' }"
          class="d-flex"
          role="button"
          aria-label="settings"
        >
          <span class="header-icon">
            <v-icon :icon="mdiCog" />
          </span>
          {{ trans("dropdown.settings") }}
        </NuxtLink>
      </v-list-item>
      <v-list-item>
        <NuxtLink
          :to="{ name: 'support' }"
          class="d-flex"
          role="button"
          aria-label="support"
        >
          <span class="header-icon">
            <v-icon :icon="mdiHelp" />
          </span>
          {{ trans("dropdown.support") }}
        </NuxtLink>
      </v-list-item>
      <v-list-item v-if="role === 'manager' && !isAdmin">
        <NuxtLink
          :to="{ name: 'admin' }"
          class="d-flex"
          role="button"
          aria-label="admin"
        >
          <span class="header-icon">
            <v-icon :icon="mdiCog" />
          </span>
          {{ trans("dropdown.admin") }}
        </NuxtLink>
      </v-list-item>
      <v-list-item v-if="role === 'manager' && isAdmin">
        <NuxtLink
          :to="{ name: 'index' }"
          class="d-flex"
          role="button"
          aria-label="application"
        >
          <span class="header-icon">
            <v-icon :icon="mdiApplication" />
          </span>
          {{ trans("dropdown.application") }}
        </NuxtLink>
      </v-list-item>
      <v-list-item>
        <NuxtLink
          :to="{ name: 'index' }"
          class="d-flex"
          role="button"
          aria-label="logout"
          @click="() => sess.signOut()"
        >
          <span class="header-icon">
            <v-icon :icon="mdiLogout" />
          </span>
          {{ trans("dropdown.logout") }}
        </NuxtLink>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <HeaderV3
    v-model:query="query"
    :securities="securities"
    :locale="locale"
    :is-admin-area="isAdmin"
    @toggle-drawer="isDrawerOpen = !isDrawerOpen"
    @security-click="onSecurityClick"
  />
  <v-main>
    <ErrorBoundary>
      <slot />
    </ErrorBoundary>
  </v-main>
  <v-bottom-navigation
    v-if="useMobileBottomNavigation"
    location="bottom start"
    grow
    style="left: 0; right: 0; width: 100%; z-index: 1050"
    color="primary"
    aria-label="bottom navigation"
    role="navigation"
    class="bottom-navigation"
  >
    <v-btn
      :to="{ name: 'portfolio' }"
      class="d-flex bottom-navigation-button"
      value="portfolio"
      aria-label="portfolio"
      role="button"
    >
      <v-icon :icon="mdiWallet"></v-icon>
      {{ trans("bottom_nav.portfolio") }}
    </v-btn>
    <v-btn
      :to="{ name: 'securities' }"
      class="d-flex bottom-navigation-button"
      value="securities"
      aria-label="securities"
      role="button"
    >
      <v-icon :icon="mdiShopping"></v-icon>
      {{ trans("bottom_nav.securities") }}
    </v-btn>
    <v-btn
      :to="{ name: 'search' }"
      class="d-flex bottom-navigation-button"
      value="index"
      aria-label="search"
      role="button"
    >
      <v-icon :icon="mdiMagnify"></v-icon>
      {{ trans("bottom_nav.search") }}
    </v-btn>
  </v-bottom-navigation>
</template>

<style lang="scss">
.header-icon {
  width: 20px;
  height: 20px;
  svg {
    stroke: white;
    fill: white;
  }
}

a.router-link-exact-active {
  color: white;
  font-weight: 400;
}

a.router-link-exact-active::after {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 30px;
  right: 30px;
  height: 1px;
  background-color: rgb(48, 48, 48);
}

.bottom-navigation {
  background-color: rgb(18, 18, 18);
  z-index: 1050;
  .bottom-navigation-button {
    max-width: unset !important;
  }
}
</style>
