<template>
  <svg
    id="meteor-icon-kit__regular-user"
    width="800px"
    height="800px"
    viewBox="-1 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2C7.79086 2 6 3.79086 6 6C6 8.20914 7.79086 10 10 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 10 2zM10 0C13.3137 0 16 2.68629 16 6C16 9.3137 13.3137 12 10 12C6.68629 12 4 9.3137 4 6C4 2.68629 6.68629 0 10 0zM2 21.099C2 21.6513 1.55228 22.099 1 22.099C0.44772 22.099 0 21.6513 0 21.099V19C0 16.2386 2.23858 14 5 14H15.0007C17.7621 14 20.0007 16.2386 20.0007 19V21.099C20.0007 21.6513 19.553 22.099 19.0007 22.099C18.4484 22.099 18.0007 21.6513 18.0007 21.099V19C18.0007 17.3431 16.6576 16 15.0007 16H5C3.34315 16 2 17.3431 2 19V21.099z"
      fill="#758CA3"
    />
  </svg>
</template>
